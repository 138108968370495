<template>
  <NuxtImg
    v-if="image"
    v-bind="filteredAttrs"
    :provider="image.provider"
    :src="image.path"
    :alt="image.alt"
    :class="mergedClasses"
    @click="onClickImage"
  />
  <UModal
    v-if="hasZoom"
    v-model="isModalOpen"
    :ui="{
      container: 'items-center',
      width: 'sm:max-w-[1100px]',
      margin: 'mx-4',
    }"
  >
    <NuxtImg
      v-if="image"
      :provider="image.provider"
      :src="image.path"
      :alt="image.alt"
      placeholder
      width="1100"
      sizes="1100px xs:2200px"
      class="h-full max-h-[calc(100vh-4rem)] w-full cursor-zoom-out object-contain"
      @click="isModalOpen = false"
    />
  </UModal>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge';
import type { DisplayImage } from '@tn/shared';

defineOptions({
  // TODO: setting this to false to avoid warning (but not sure if right).
  inheritAttrs: false,
});

const attrs = useAttrs();

// Filter out the class attribute to avoid duplication
const { class: attrsClass, ...filteredAttrs } = attrs;

const mergedClasses = computed(() =>
  twMerge(
    'h-full w-full object-contain',
    props.hasZoom ? 'cursor-zoom-in' : '',
    attrsClass as string
  )
);

const isModalOpen = ref(false);
const props = defineProps<{
  image?: DisplayImage;
  hasZoom?: boolean;
}>();

const onClickImage = () => {
  if (props.hasZoom) {
    isModalOpen.value = true;
  }
};
</script>
